import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { auth, db } from '../../firebase';
import { setDoc, doc, collection, query, getDocs, arrayUnion, getDoc } from 'firebase/firestore';

const formatUrl = (text) => text.replace(/\s+/g, '-');

const generateRandomGradient = (course_title) => {
  let hash = 0;
  for (let i = 0; i < course_title.length; i++) {
    hash = course_title.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h1 = hash % 360;
  const h2 = (hash + 100) % 360;
  return {
    color1: `hsl(${h1}, 70%, 70%)`,
    color2: `hsl(${h2}, 70%, 50%)`,
  };
};

export default function CourseDetail() {
  const { course_title } = useParams();
  const navigate = useNavigate();
  const formattedCourseTitle = course_title.replace(/-/g, ' ');
  const [course, setCourse] = useState(null);
  const [activeIndices, setActiveIndices] = useState([]);
  const { color1, color2 } = generateRandomGradient(formattedCourseTitle);

  const [showFullBreadcrumb, setShowFullBreadcrumb] = useState(false);

  const toggleBreadcrumb = () => {
      setShowFullBreadcrumb(!showFullBreadcrumb);
  };

  const handleStartLearning = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login', { state: { from: window.location.pathname } });
        return;
      }

      const userId = user.uid;
      const enrollmentsRef = collection(db, 'enrollments');
      const enrollmentDocRef = doc(enrollmentsRef, `${userId}_${course.id}`);
      const enrollmentDoc = await getDoc(enrollmentDocRef);

      if (enrollmentDoc.exists()) {
        const enrollmentData = enrollmentDoc.data();
        if (enrollmentData.currentLesson) {
          const [, moduleId, lessonId] = enrollmentData.currentLesson.split('_'); // Removed courseId
          const currentModule = course.modules.find(module => module.id === moduleId);
          const currentLesson = currentModule.lessons.find(lesson => lesson.id === lessonId);
          if (currentModule && currentLesson) {
            const path = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(currentModule.module_title)}/${formatUrl(currentLesson.lesson_slug)}`;
            navigate(path);
            return;
          }
        }
      }

      if (course.modules && course.modules.length > 0) {
        const firstModule = course.modules[0];
        if (firstModule.lessons && firstModule.lessons.length > 0) {
          const firstLesson = firstModule.lessons[0];

          const path = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(
            firstModule.module_title
          )}/${formatUrl(firstLesson.lesson_slug)}`;

          const enrollmentData = {
            userId: userId,
            courseId: course.id,
            title: course.course_title,
            category: course.category,
            startDate: new Date().toISOString(),
            status: 'active',
            currentLesson: `${course.id}_${firstModule.id}_${firstLesson.id}`
          };

          await setDoc(enrollmentDocRef, enrollmentData, { merge: true });
          const userRef = doc(db, 'users', userId);
          const userEnrollmentsRef = collection(userRef, 'Enrollments');
          await setDoc(
            doc(userEnrollmentsRef, userId),
            {
              enrolledCourses: arrayUnion(course.id), 
              enrollmentID: arrayUnion(`${userId}_${course.id}`), 
            },
            { merge: true }
          );
          navigate(path);
        }
      }
    } catch (error) {
      console.error('Error starting learning:', error);
    }
  };

  useEffect(() => {
    const fetchCourseData = async () => {
      const coursesRef = collection(db, 'courses');
      const q = query(coursesRef);
      const querySnapshot = await getDocs(q);

      const courseDoc = querySnapshot.docs.find((doc) => doc.data().course_title === formattedCourseTitle);
      const courseData = { id: courseDoc.id, ...courseDoc.data() };

      setCourse(courseData);

      const modulesRef = collection(db, 'courses', courseDoc.id, 'modules');
      const modulesSnapshot = await getDocs(modulesRef);

      const modules = modulesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const lessonsPromises = modules.map(async (module) => {
        const lessonsRef = collection(
          db,
          'courses',
          courseDoc.id,
          'modules',
          module.id,
          'lessons'
        );
        const lessonsSnapshot = await getDocs(lessonsRef);

        const lessons = lessonsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return { ...module, lessons };
      });

      const modulesWithLessons = await Promise.all(lessonsPromises);

      setCourse({
        ...courseData,
        modules: modulesWithLessons,
      });
    };

    fetchCourseData();
  }, [formattedCourseTitle]);

  const toggleAccordion = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

  if (!course) {
    return <div className="text-center text-gray-600 font-semibold py-8">Loading course data...</div>;
  }

  return (
    <div className="min-h-screen bg-white lg:-mt-16 ">
      <header className="relative text-white">
        <svg
          className="absolute inset-0 w-full h-full"
          viewBox="0 0 400 200"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio= "none"
        >
          <defs>
            <linearGradient id={`grad-${course?.courseID || "default"}`} x1="0%" y1="0%" x2="100%">
              <stop offset="0%" stopColor={color1 || "#000"} />
              <stop offset="100%" stopColor={color2 || "#fff"} />
            </linearGradient>
          </defs>
          <rect width="400" height="200" fill={`url(#grad-${course?.courseID || "default"})`} />
        </svg>

        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>

        <div className="relative max-w-6xl mx-auto text-center lg:text-left py-8">
          <nav className="mb-6 text-white text-sm font-medium">
            <Link to="/" className="hover:text-blue-600">Home</Link>
            {showFullBreadcrumb || window.innerWidth >= 640 ? (
              <>
                <span className="mx-2">&gt;</span>
                <Link to={`/${formatUrl(course.category)}`} className="hover:text-blue-600">{course.category}</Link>
              </>
            ) : (
              <>
                <span className="mx-2">&gt;</span>
                <button onClick={toggleBreadcrumb} className="hover:text-blue-600 sm:hidden">...</button>
                <span className="mx-2 hidden sm:inline">&gt;</span>
              </>
            )}
            <span className="mx-2">&gt;</span>
            <Link to={`/${formatUrl(course.category)}/${formatUrl(course.course_title)}`} className="hover:text-blue-600">{course.course_title}</Link>
          </nav>
          <h1 className="text-4xl font-bold">{course?.course_title || "Untitled Course"}</h1>
          <p className="mt-2 text-gray-200 text-lg">{course?.platform || "Unknown Platform"}</p>
          {/*
          <div className="mt-4 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
            <div className="flex items-center text-gray-200">
              <Clock className="h-5 w-5" />
              <span className="ml-2">{course?.duration || "Unknown Duration"}</span>
            </div>
            <div className="flex items-center text-gray-200">
              <Users className="h-5 w-5" />
              <span className="ml-2">
                {Array.isArray(course?.instructor) && course.instructor.length > 0
                  ? `${course.instructor[0]} + ${course.instructor.length - 1}`
                  : course?.instructor || "Unknown Instructor"}
              </span>
            </div>
          </div>
          */}
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid gap-8 lg:grid-cols-[70%,30%]">
          <div>
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Overview</h2>
              <p className="text-gray-600 leading-relaxed">{course.overview}</p>
            </section>
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">What skills you'll gain</h2>
              {Array.isArray(course.skills) && course.skills.length > 0 ? (
                <ul className="flex flex-wrap gap-8 text-gray-600">
                  {course.skills.map((skill, index) => (
                    <li key={index} className="flex items-center bg-gray-100 px-2 py-1 rounded -ml-6 gap-8">
                      <span className="text-gray-800">{skill}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-600">No skills information available.</p>
              )}
            </section>

            <section className="p-6 bg-white shadow-md rounded-lg -ml-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Syllabus</h2>
              {Array.isArray(course.modules) && course.modules.length > 0 ? (
                course.modules.map((module, index) => (
                  <div key={index} className="border-b py-4 mt-2">
                    <button
                      onClick={() => toggleAccordion(index)}
                      className="flex items-center justify-between w-full text-left text-gray-800 font-medium"
                    >
                      <span className="text-lg flex-shrink-0">{module.module_title}</span> 
                      <span className="flex-grow"></span>
                      {activeIndices.includes(index) ? (
                        <ChevronUp className="h-6 w-6 flex-shrink-0" />
                      ) : (
                        <ChevronDown className="h-6 w-6 flex-shrink-0" />
                      )}
                    </button>
                    <span className="text-xs text-gray-500 block mt-1 ">
                      Module {index + 1} • {module.completionTime}
                    </span>
                    {activeIndices.includes(index) && (
                      <>
                        {Array.isArray(module.lessons) && module.lessons.length > 0 ? (
                          <ul className="mt-2 text-gray-600 space-y-2 pl-4 -ml-4 ">
                            {module.lessons.map((lesson, idx) => (
                              <li
                                key={idx}
                                className="flex justify-between items-center py-2 border-b"
                              >
                                <div className="flex items-center space-x-2">
                                  <span className="text-gray">{lesson.lesson_title}</span>
                                </div>
                                <span className="text-sm text-gray-500 flex-shrink-0">{lesson.duration}</span>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="mt-2 text-gray-500 pl-4">No lessons available.</p>
                        )}
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No syllabus information available.</p>
              )}
            </section>
          </div>

              {/* Container */}
          <aside>
            <div className="mb-6 bg-white shadow-md rounded-lg overflow-hidden border border-gray-300">
              <div className="relative aspect-video" style={{ background: `linear-gradient(135deg, ${color1}, ${color2})` }}>
                <div className="absolute inset-0 flex items-center justify-center">
                  <h2 className="text-2xl font-bold text-white text-center px-4">{course.course_title}</h2>
                </div>
              </div>
              <div className="p-6">
                <button
                  onClick={handleStartLearning}
                  className="w-full py-2 px-4 bg-blue-800 text-white rounded-lg hover:bg-blue-700"
                >
                  Start Learning
                </button>
                {/*
                <div className="space-y-5 mt-6">
                  <div className="flex items-center">
                    <img
                      src={Array.isArray(course.instructorProfilePicture) ? course.instructorProfilePicture[0] : course.instructorProfilePicture}
                      alt={Array.isArray(course.instructor) ? course.instructor[0] : course.instructor}
                      className="h-10 w-10 rounded-full"
                    />
                    <div className="ml-4">
                      <p className="text-lg font-medium">
                        {Array.isArray(course.instructor) && course.instructor.length > 0
                          ? `${course.instructor[0]} + ${course.instructor.length - 1}`
                          : course.instructor}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 space-y-4 ml-2">
                    <p className="flex items-center text-gray-600">
                      <Clock className="mr-2 h-5 w-5" /> {course.duration}
                    </p>
                    <p className="flex items-center text-gray-600">
                      <BarChart2 className="mr-2 h-5 w-5" /> {course.level}
                    </p>
                    <p className="flex items-center text-gray-600">
                      <Globe2 className="mr-2 h-5 w-5" /> {course.language}
                    </p>
                  </div>
                </div>
                */}
              </div>
            </div>
          </aside>
        </div>
        {/*
        <section className="flex items-center justify-between p-6 bg-white shadow-md rounded-lg mt-8">
          <div className="max-w-md">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Earn a Certificate</h2>
            <p className="text-gray-600">
              Get a certificate of completion after completing the course.
            </p>
          </div>
          <div className="flex-shrink-0 ml-4">
            <img
              src="https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera_assets.s3.amazonaws.com/images/de1a6556fbe605411e8c1c2ca4ba45f1.png?auto=format%2Ccompress&dpr=1&w=333&h=215&q=40"
              alt="Certificate"
              className="h-32 w-32 object-cover rounded-lg border border-gray-300 shadow-sm"
            />
          </div>
        </section>
        */}
      </main>
    </div>
  );
}