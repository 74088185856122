import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Ensure this path is correct

const PublicRoute = ({ element }) => {
  const { user } = useUser();

  if (user === undefined) {
    // You can return a loading spinner or null while the user state is being determined
    return <div>Loading...</div>;
  }

  return user ? <Navigate to="/all-courses" /> : element;
};

export default PublicRoute;