import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth, db } from '../firebase'; 
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext({
  user: undefined,
  login: async () => {},
  logout: async () => {},
  enrolledCourses: async () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined); 
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const basicUser = { uid: authUser.uid };

        try {
          const userProfileRef = doc(db, 'users', authUser.uid, 'UserProfile', authUser.uid);
          const userProfileDoc = await getDoc(userProfileRef);

          let userProfile = {};
          if (userProfileDoc.exists()) {
            userProfile = userProfileDoc.data();
          }

          // const onboardingCheckRef = doc(db, 'users', authUser.uid);
          // const onboardingCheckDoc = await getDoc(onboardingCheckRef);

          // const isOnboardingDone = onboardingCheckDoc.exists() && onboardingCheckDoc.data().isOnboardingDone;

          // if (!isOnboardingDone) {
          //   setUser({
          //     ...basicUser,
          //     ...userProfile,
          //     isOnboardingDone: false,
          //   });
          //   navigate('/onboarding');
          // } else {
            setUser({
              ...basicUser,
              ...userProfile,
              isOnboardingDone: true,
            });
          // }
        } catch (error) {
          console.error('Error fetching user info from Firestore:', error);
          setUser(basicUser); 
        }
      } else {
        setUser(null); 
      }
    });

    return () => unsubscribe(); 
  }, [navigate]);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const enrolledCourses = async () => {
    if (!user) return [];
    try {
      const enrollmentsRef = collection(db, 'users', user.uid, 'Enrollments');
      const enrollmentsSnapshot = await getDocs(enrollmentsRef);
      const courses = enrollmentsSnapshot.docs.map(doc => doc.data().enrolledcoursestitle);
      return courses;
    } catch (error) {
      console.error('Error fetching enrolled courses:', error);
      return [];
    }
  };

  return (
    <UserContext.Provider value={{ user, login, logout, enrolledCourses }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
