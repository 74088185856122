import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const PromotionalLine = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // You can change the animation duration
    });
  }, []);

  return (
    <section className="bg-neutral-50 p-5 sm:p-10 sm:mobile-margin">
      <div className="px-4 pt-20 md:pt-40 pb-32 md:pb-56 bg-green-100 rounded-5xl">
        <div className="flex flex-col justify-center items-center h-full">
          <h1 className="mb-20 sm:mb-20 mobile-gap text-center text-neutral-800 text-4xl sm:text-9xl xl:text-11xl font-semibold max-w-5xl mx-auto font-heading">
            Join the community and change the future.
          </h1>

          {/* Button type container */}
          <div className="bg-green-400 rounded-6xl p-6 md:p-12 mobile-dimensions">
            <p className="text-4xl sm:text-10xl md:text-11xl lg:text-12xl xl:text-14xl font-semibold tracking-tight text-neutral-800 text-center single-line mobile-text">
              <span>1x</span>
              <span className="text-4xl sm:text-8xl md:text-9xl lg:text-10xl xl:text-12xl">
              </span>
              <span>Better Daily</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};


export default PromotionalLine;
