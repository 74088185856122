import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../../firebase'; // Import db from firebase
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Import Firestore functions

export default function LoginSection() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const from = location.state?.from || '/';
      navigate(from);
    } catch (err) {
      setError('Failed to sign in. Please check your email and password.');
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Fetch user details
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        const { email, displayName, photoURL, metadata } = user;
        const [firstName, lastName] = displayName.split(' ');

        await setDoc(userDocRef, {
          userID: user.uid
        });

        const userProfileDocRef = doc(db, 'users', user.uid, 'UserProfile', user.uid);
        await setDoc(userProfileDocRef, {
          createdAt: metadata.creationTime,
          emailAddress: email,
          firstName: firstName || '',
          lastName: lastName || '',
          profilePhoto: photoURL || ''
        });
      }

      const from = location.state?.from || '/';
      navigate(from);
    } catch (err) {
      setError('Failed to sign in with Google.');
    }
  };

  return (
    <section className='bg-white pt-4 pb-12 md:pb-24'>
      <div className='container mx-auto px-4'>
        <div className='max-w-xl mx-auto'>
          <form onSubmit={handleSignIn}>
            <h3 className='text-4xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl font-semibold mb-16'>
              Sign in
            </h3>
            {error && <p className='text-red-500 mb-4'>{error}</p>}
            <button
              type='button'
              className='flex justify-center items-center text-center w-full mb-6 p-4 font-semibold tracking-tight border border-neutral-900 bg-white hover:border-blue-500 hover:text-blue-500 focus:border-blue-600 focus:text-blue-600 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
              aria-label='Sign in with Google'
              onClick={handleGoogleSignIn}
            >
              <img
                className='mr-2 h-6'
                src='/mirga-assets/images/sign-in/google.svg' // Corrected path
                alt='Google logo'
              />
              <span>Continue with Google</span>
            </button>
            <p className='text-center text-neutral-600 font-medium tracking-tight mb-4'>
              OR
            </p>
            <label
              className='block mb-3 text-neutral-600 font-medium tracking-tight'
              htmlFor='email'
            >
              Email address
            </label>
            <input
              className='w-full mb-6 px-8 py-5 text-lg outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-gray-600 transition duration-200'
              id='email'
              type='email'
              placeholder='ex. d.duncan@email.com'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label
              className='block mb-3 text-neutral-600 font-medium tracking-tight'
              htmlFor='password'
            >
              Password
            </label>
            <input
              className='w-full mb-4 px-8 py-5 text-lg outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-gray-600 transition duration-200'
              id='password'
              type='password'
              placeholder='Enter your password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <a
              className='flex justify-end text-neutral-600 font-medium mb-12 hover:text-neutral-800 tracking-tight transition duration-200'
              href='/forgot-password'
            >
              Forgot my password
            </a>
            <button
              className='flex justify-center items-center text-center w-full mb-6 p-5 font-semibold tracking-tight text-lg text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
              type='submit'
              aria-label='Sign in button'
            >
              Sign in
            </button>
            <a
              className='inline-block text-neutral-600 text-sm font-medium hover:text-neutral-800 tracking-tight transition duration-200'
              href='/register'
            >
              Don't have an account? Sign Up
            </a>
          </form>
        </div>
      </div>
    </section>
  );
}
