import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sampleJobs } from './jobsData';

const JobDetailPage = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);

  useEffect(() => {
    const fetchJobData = () => {
      const jobData = sampleJobs.find(job => job.id === parseInt(id));
      setJob(jobData);
    };

    fetchJobData();
  }, [id]);

  if (!job) {
    return <div className="text-center text-gray-500">Job not found</div>;
  }

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  return (
    <div className="max-w-6xl mx-auto p-8 bg-white shadow-lg rounded-lg">
      <div className="flex items-center gap-6 mb-6">
        <img src={job.logo} alt={job.company} className="w-20 h-20 shadow-md" />
        <div>
          <h1 className="text-4xl font-bold text-gray-900">{job.title}</h1>
          <p className="text-gray-600 text-lg">{job.company} - {job.location}</p>
        </div>
      </div>
      
      <div className="flex justify-between text-gray-600 mt-4 border-b pb-4 flex-wrap">
        <div className="flex items-center gap-2 flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
            <path d="M12 20h9"></path>
            <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z"></path>
          </svg>
          <span className="font-medium text-sm sm:text-base md:text-lg lg:text-xl">Category: {job.category}</span>
        </div>
        
        <div className="flex items-center gap-2 flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
            <path d="M2 12l5 5L12 9l5 5 5-5"></path>
          </svg>
          <span className="font-medium text-sm sm:text-base md:text-lg lg:text-xl">Type: {job.type}</span>
        </div>

        <div className="flex items-center gap-2 flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
          <span className="font-medium text-sm sm:text-base md:text-lg lg:text-xl">Posted on: {formatDate(job.date)}</span>
        </div>
      </div>
      
      <p className="text-gray-800 mt-6 leading-relaxed text-lg text-justify">{job.description}</p>
      
      <div className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900">Skills</h2>
        <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
          {job.skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900">Requirements</h2>
        <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
          {job.requirements.map((req, index) => (
            <li key={index}>{req}</li>
          ))}
        </ul>
      </div>
      
      <div className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900">Responsibilities</h2>
        <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
          {job.responsibilities.map((resp, index) => (
            <li key={index}>{resp}</li>
          ))}
        </ul>
      </div>

      {Array.isArray(job.eligibility) && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-900">Eligibility</h2>
          <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
            {job.eligibility.map((elig, index) => (
              <li key={index}>{elig}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="mt-8 text-center">
        <button className="bg-blue-800 text-white py-3 px-6 text-lg rounded-full hover:bg-blue-600 w-full">
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default JobDetailPage;
