import React from 'react';
import { ArrowUpRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const generateRandomGradient = (course_title) => {
  let hash = 0;
  for (let i = 0; i < course_title.length; i++) {
    hash = course_title.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h1 = hash % 360;
  const h2 = (hash + 100) % 360;
  return {
    color1: `hsl(${h1}, 70%, 70%)`,
    color2: `hsl(${h2}, 70%, 50%)`,
  };
};

const formatUrl = (text) => text.replace(/\s+/g, '-');

const CourseCard = ({ course }) => { 
  const navigate = useNavigate(); 
  const { color1, color2 } = generateRandomGradient(course.course_title);

  const handleCourseClick = () => {
    navigate(`/${formatUrl(course.category)}/${formatUrl(course.course_title)}`);
  };

  /*
  const displayinstructor = (instructor) => {
    if (!instructor || instructor.length === 0) return 'Unknown Instructor';
    if (instructor.length === 1) {
      return truncateText(instructor[0], 15);
    } else {
      return `${truncateText(instructor[0], 15)} +${instructor.length - 1}`;
    }
  };
  */

  return (
    <div className="w-96 flex-none bg-white rounded-lg overflow-hidden shadow-lg border border-gray-200 transition-transform duration-300 hover:scale-105 mx-4 cursor-pointer" onClick={handleCourseClick}>
      <div className="relative h-50">
        <svg className="w-full h-full" viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id={`grad-${course.courseID}`} x1="0%" y1="0%" x2="100%">
              <stop offset="0%" stopColor={color1} />
              <stop offset="100%" stopColor={color2} />
            </linearGradient>
          </defs>
          <rect width="400" height="200" fill={`url(#grad-${course.courseID})`} />
        </svg>
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
          <h3 className="text-2xl font-bold text-white cursor-pointer max-w-full break-words">
            {truncateText(course.course_title, 40)}
          </h3>
        </div>
          {/* 
        <div className="absolute top-4 right-4 flex items-center bg-white text-gray-800 rounded-full px-2 py-1 shadow-md">
          <Clock className="w-4 h-4 mr-2 text-purple-500" />
          <span className="text-sm font-semibold">{course.duration || '4 Weeks'}</span>
        </div>
        <div className="absolute top-4 left-4 flex items-center bg-white text-gray-800 rounded-full px-2 py-1 shadow-md">
          <img
            className="w-8 h-6 rounded-full mr-2 object-cover border border-gray-300"
            src={course.instructorProfilePicture}
            alt={course.instructor}
          />
          <span className="text-sm font-semibold">{displayinstructor(course.instructor)}</span>
        </div>
          */ }
      </div>

      <div className="p-4">
        <h3 className="text-gray-700 mb-3">{truncateText(course.overview, 100)}</h3>
        {/* 
        <div className="grid grid-cols-2 gap-3 text-sm mb-4">
          <div className="flex items-center gap-2">
            <Tag className="w-4 h-4 text-blue-500" />
            <span className="text-gray-600">{course.language || 'English'}</span>
          </div>
          <div className="flex items-center gap-2">
            <BookOpen className="w-4 h-4 text-black-500" />
            <span className="text-gray-600">{course.level || 'Beginner'}</span>
          </div>
          <div className="flex items-center gap-2">
            <DollarSign className="w-4 h-4 text-yellow-500" />
            <span className="text-gray-600">{course.price || 'Free'}</span>
          </div>
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-purple-500" />
            <span className="text-gray-600">{course.duration || '4 Weeks'}</span>
          </div>
        </div>
         */ }

        <div className="flex justify-between items-center">
          <ArrowUpRight className="w-10 h-10 text-blue-700 hover:text-blue-900 transition-colors duration-300" />
          <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors">
            Explore
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;