import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import HeroSection from '../components/hero/HeroSection';
import CTASection from '../components/cta/CTASection';
import FeaturePoint from '../components/home/FeaturePoint';
import LearnPractice from '../components/home/LearnPractice';
import PromotionalLine from '../components/home/PromotionalLine';

const meta = {
  title: '1xSkills - One Platform For All Your Career Needs',
  meta: [
    { name: 'description', content: 'One platform to manage all your career needs including learning, practicing, and promotional opportunities.' },
    { property: 'og:title', content: '1xSkills - One Platform For All Your Career Needs' },
    { property: 'og:description', content: 'One platform to manage all your career needs including learning, practicing, and promotional opportunities.' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://1xskills.com' },
    { property: 'og:image', content: '/1xSkills-Logo.webp' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: '1xSkills - One Platform For All Your Career Needs' },
    { name: 'twitter:description', content: 'One platform to manage all your career needs including learning, practicing, and promotional opportunities.' },
    { name: 'twitter:image', content: '/1xSkills-Logo.webp' }
  ]
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <HeroSection />
      <FeaturePoint/>
      <LearnPractice/>
      <PromotionalLine/>
      <CTASection />

    </React.Fragment>
  );
}

