import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CTASection() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            alert('Please enter your email');
            return;
        }
        navigate(`/register?email=${email}`);
    };

    return (
        <React.Fragment>
            <section className="relative py-24">
                <div className="relative container px-4 mx-auto">
                    <div className="relative max-w-md mx-auto text-center">
                        <span className="inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-blue-700 bg-blue-200 rounded-full">Exclusive offer for 1st Corhot</span>
                        <h1 className="font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4">Try 1xSkills today</h1>
                        <p className="text-gray-500 mb-10">Take a step toward more career aligned partner for your success.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="xs:flex items-center">
                                <input
                                    className="py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-blue-500 outline-none ring ring-transparent focus:ring-blue-500 rounded-lg"
                                    type="email"
                                    placeholder="Enter your email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button
                                    className="xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-blue-800 to-blue-800 rounded-lg transition-all duration-300 focus:outline-none"
                                    type="submit"
                                >
                                    <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-blue-300 animate-pulse group-hover:ring-0 transition duration-300" />
                                    <span>Get Started</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

