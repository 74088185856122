import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export default function RegisterSectionSignUpWhite1() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
  });
  const [message, setMessage] = useState({ type: '', text: '' });
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setFormData((prevData) => ({ ...prevData, email: emailParam }));
    }
  }, [location]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { name, phone, email, password } = formData;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,16}$/;

    if (!name || !phone || !email || !password) {
      setMessage({ type: 'error', text: 'All fields are required.' });
      return false;
    }
    if (!/^[0-9]{10}$/.test(phone)) {
      setMessage({ type: 'error', text: 'Please enter a valid 10-digit phone number.' });
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setMessage({ type: 'error', text: 'Please enter a valid email address.' });
      return false;
    }
    if (!passwordRegex.test(password)) {
      setMessage({
        type: 'error',
        text: 'Password must be 8-16 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.',
      });
      return false;
    }
    return true;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setMessage({ type: '', text: '' });

    if (!validateForm()) return;

    setLoading(true);
    const { name, phone, email, password } = formData;
    const [firstName, ...lastNameArr] = name.split(' ');
    const lastName = lastNameArr.join(' ');

    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create main document in 'users' collection
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, {
        UserID: user.uid,
      });

      const userProfileRef = doc(userDocRef, 'UserProfile', user.uid);
      await setDoc(userProfileRef, {
        firstName: firstName || '',
        lastName: lastName || '',
        phoneNumber: phone,
        emailAddress: user.email,
        createdAt: user.metadata.creationTime,
      });

      setMessage({ type: 'success', text: 'Account created successfully!' });
      setFormData({ name: '', phone: '', email: '', password: '' });
    } catch (err) {
      console.error('Error:', err.message);
      setMessage({ type: 'error', text: 'An error occurred while signing up. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Create main document in 'users' collection
      const userDocRef = doc(db, 'users', user.uid);
      const onboardingCheckDoc = await getDoc(userDocRef);
      const isOnboardingDone = onboardingCheckDoc.exists() && onboardingCheckDoc.data().isOnboardingDone;
      if (isOnboardingDone === true) {
        setMessage({ type: 'success', text: 'Signed in with Google successfully!' });
        return;
      }
      else if (!isOnboardingDone) {
        await setDoc(userDocRef, { isOnboardingDone: false }, { merge: true });
        await setDoc(userDocRef, { UserID: user.uid }, { merge: true });
      }

      const userProfileRef = doc(userDocRef, 'UserProfile', user.uid);
      await setDoc(userProfileRef, {
        firstName: user.displayName.split(' ')[0] || '',
        lastName: user.displayName.split(' ').slice(1).join(' ') || '',
        phoneNumber: user.phoneNumber || '',
        emailAddress: user.email,
        createdAt: user.metadata.creationTime,
        profilePhoto: user.photoURL || 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'
      });

      setMessage({ type: 'success', text: 'Signed in with Google successfully!' });
    } catch (err) {
      console.error('Error:', err.message);
      setMessage({ type: 'error', text: 'An error occurred while signing in with Google. Please try again.' });
    }
  };

  return (
    <section className="bg-white">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-3xl overflow-hidden">
          <div className="flex justify-center">
            <div className="w-full xl:w-1/2">
              <div className="px-8 md:px-16 pt-16 pb-20">
                <form onSubmit={handleSignUp} noValidate>
                  <span className="inline-block text-neutral-600 text-xl font-semibold tracking-tight mb-4">
                    Account
                  </span>
                  <h1 className="text-6xl font-semibold tracking-tight mb-6 font-heading">
                    Get Started for Free
                  </h1>
                  <p className="text-neutral-600 font-medium tracking-tight mb-16">
                    Sign up to access exclusive features on our platform.
                  </p>
                  <button
                    type='button'
                    className='flex justify-center items-center text-center w-full mb-6 p-4 font-semibold tracking-tight border border-neutral-900 bg-white hover:border-blue-500 hover:text-blue-500 focus:border-blue-600 focus:text-blue-600 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                    aria-label='Sign in with Google'
                    onClick={handleGoogleSignIn}
                  >
                    <img
                      className='mr-2 h-6'
                      src='/mirga-assets/images/sign-in/google.svg' 
                      alt='Google logo'
                    />
                    <span>Continue with Google</span>
                  </button>
                  <p className='text-center text-neutral-600 font-medium tracking-tight mb-4'>
                    OR
                  </p>
                  {message.text && (
                    <p
                      className={`mb-4 ${
                        message.type === 'error' ? 'text-red-500' : 'text-green-500'
                      }`}
                    >
                      {message.text}
                    </p>
                  )}
                  <div className="flex flex-wrap -m-3 mb-3">
                    <div className="w-full p-3">
                      <label className="block mb-1 text-neutral-600 font-medium" htmlFor="name">
                        Name
                      </label>
                      <input
                        className="w-full px-8 py-5 outline-none rounded-lg border placeholder-neutral-300 font-medium focus:ring-4 transition duration-200"
                        id="name"
                        type="text"
                        placeholder="e.g., John Doe"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="w-full p-3">
                      <label className="block mb-1 text-neutral-600 font-medium" htmlFor="phone">
                        Phone
                      </label>
                      <input
                        className="w-full px-8 py-5 outline-none rounded-lg border placeholder-neutral-300 font-medium focus:ring-4 transition duration-200"
                        id="phone"
                        type="tel"
                        placeholder="e.g., 555-555-5555"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="w-full p-3">
                      <label className="block mb-1 text-neutral-600 font-medium" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="w-full px-8 py-5 outline-none rounded-lg border placeholder-neutral-300 font-medium focus:ring-4 transition duration-200"
                        id="email"
                        type="email"
                        placeholder="e.g., john@example.com"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="w-full p-3">
                      <label className="block mb-1 text-neutral-600 font-medium" htmlFor="password">
                        Password
                      </label>
                      <input
                        className="w-full px-8 py-5 outline-none rounded-lg border placeholder-neutral-300 font-medium focus:ring-4 transition duration-200"
                        id="password"
                        type="password"
                        placeholder="Enter your password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="w-full p-3">
                      <button
                        className="flex justify-center items-center text-center h-full w-full p-5 font-semibold text-lg text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? 'Processing...' : 'Sign Up Now'}
                      </button>
                    </div>
                  </div>
                  <a
                    className="inline-block text-neutral-600 text-sm font-medium hover:text-neutral-800 tracking-tight transition duration-200"
                    href="login"
                  >
                    Already have an account? Log in
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}