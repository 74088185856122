import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import CourseCard from './CourseCard';

const groupCoursesByCategory = (courses) => {
  return courses.reduce((acc, course) => {
    if (!acc[course.category]) {
      acc[course.category] = [];
    }
    acc[course.category].push(course);
    return acc;
  }, {});
};

const AllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [filterPopupVisible, setFilterPopupVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesCollection = collection(db, 'courses');
      const coursesSnapshot = await getDocs(coursesCollection);
      const coursesList = coursesSnapshot.docs.map(doc => doc.data());
      setCourses(coursesList);
    };

    fetchCourses();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setFilterPopupVisible(false); 
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const filteredCourses = courses.filter((course) => {
    const matchesCategory = selectedCategory ? course.category === selectedCategory : true;
    const matchesSearch = course.course_title.toLowerCase().includes(searchText.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="container mx-auto p-4 relative">
      <div className="absolute top-4 right-4">
        <button
          className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full shadow hover:bg-gray-300 transition duration-200 ease-in-out transform hover:scale-110"
          title="Filter"
          onClick={() => setFilterPopupVisible(!filterPopupVisible)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-5 h-5 text-gray-600"
          >
            <path d="M4 6h16" />
            <path d="M8 12h8" />
            <path d="M10 18h4" />
          </svg>
        </button>

        {filterPopupVisible && (
          <div className="absolute mt-2 right-0 w-64 bg-white border rounded-lg shadow-lg p-4 z-50 transition duration-200 ease-in-out transform origin-top-right scale-100">
            <h3 className="text-lg font-semibold mb-2">Search Course</h3>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
              className="w-full px-3 py-2 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-600"
              style={{ padding: '10px' }}
            />
            <button
              className="w-full bg-blue-800 text-white py-2 rounded mb-4 hover:bg-blue-700 transition duration-200 ease-in-out"
            >
              Search
            </button>
            <div className="max-h-48 overflow-y-auto">
              <button
                className={`block w-full text-left px-3 py-2 mb-1 rounded transition duration-200 ease-in-out transform hover:scale-105 ${selectedCategory === null ? 'border-blue-600 text-blue-600' : 'bg-gray-100 hover:bg-gray-200'}`}
                onClick={() => handleCategoryClick(null)}
              >
                All Courses
              </button>
              {Object.keys(groupCoursesByCategory(courses)).map((category) => (
                <button
                  key={category}
                  className={`block w-full text-left px-3 py-2 mb-1 rounded transition duration-200 ease-in-out transform hover:scale-105 ${selectedCategory === category ? 'border-blue-600 text-blue-600' : 'bg-gray-100 hover:bg-gray-200'}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {Object.keys(groupCoursesByCategory(filteredCourses)).map((category) => (
        <div key={category} className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{category}</h2>
          <div className="flex flex-wrap">
            {groupCoursesByCategory(filteredCourses)[category].map((course) => (
              <CourseCard key={course.course_title} course={course} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllCourses;