import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import CourseCard from './CourseCard.js';

const CategoryPage = () => {
  const [courses, setCourses] = useState([]);
  const [filter, setFilter] = useState({
    language: '',
    level: '',
    price: '',
    skill: '',
  });
  const { category } = useParams();
  const decodedCategory = decodeURIComponent(category).replace(/-/g, ' ');

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesCollection = collection(db, 'courses');
      const coursesSnapshot = await getDocs(coursesCollection);
      const coursesList = coursesSnapshot.docs.map(doc => doc.data());
      setCourses(coursesList);
    };

    fetchCourses();
  }, []);

  const categoryCourses = courses.filter(
    (course) => course.category === decodedCategory
  );

  if (courses.length > 0 && categoryCourses.length === 0) {
    return <Navigate to="/404" />;
  }
  const uniqueSkills = [
    ...new Set(categoryCourses.flatMap((course) => course.skills || [])),
  ];

  const filteredCourses = categoryCourses.filter((course) => {
    return (
      (!filter.language ||
        course.language?.toLowerCase() === filter.language.toLowerCase()) &&
      (!filter.level ||
        course.level?.toLowerCase() === filter.level.toLowerCase()) &&
      (!filter.price || course.price?.toLowerCase() === filter.price.toLowerCase()) &&
      (!filter.skill || (course.skills || []).includes(filter.skill))
    );
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-blue-900">
        {categoryCourses[0]?.category || 'Category'}
      </h2>

      <div className="flex flex-wrap items-center gap-4 mb-4">
        <select
          name="skill"
          value={filter.skill}
          onChange={handleFilterChange}
          className="border border-gray-300 rounded px-3 py-2"
        >
          <option value="">All Skills</option>
          {uniqueSkills.map((skill, index) => (
            <option key={index} value={skill}>
              {skill}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-wrap gap-4">
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))
        ) : (
          <p className="text-gray-600">No courses found for the selected filters.</p>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;