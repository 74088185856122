import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../firebase';

export default function HeroSection() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        isAuthenticated().then(setIsAuth);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            alert('Please enter your email');
            return;
        }
        else {
            setError('');
            navigate(`/register?email=${email}`);
        }
    };

    return (
        <React.Fragment>
            <section className="pb-12 md:pb-24 mt-8 sm:mt-20">
                <div className="relative">
                    <div className="container px-4 mx-auto">
                        <motion.div
                            className="max-w-lg md:max-w-3xl mx-auto mb-10 md:mb-0 text-center"
                        >
                            <motion.h1
                                className="font-heading tracking-tight text-4xl xs:text-5xl md:text-7xl font-semibold mb-6"
                            >
                                <span>Get 1% Better Daily</span>
                                <motion.span
                                    className="inline-block ml-2 text-gray-400"
                                >
                                    with <span className="text-black">1xSkills</span><span className="text-blue-800">.</span>
                                </motion.span>
                            </motion.h1>

                            <motion.p
                                className="md:max-w-md lg:max-w-lg mx-auto text-lg text-gray-500 mb-10"
                            >
                                We are empowering students and professionals to be in the top 1% of talent pool.
                            </motion.p>

                            {!isAuth && (
                                <form onSubmit={handleSubmit}>
                                    <div className="xs:flex items-center max-w-md mx-auto mb-6">
                                        <motion.input
                                            className="w-full mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-blue-500 outline-none ring ring-transparent focus:ring-blue-500 rounded-lg"
                                            type="email"
                                            placeholder="Your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <motion.button
                                            className="xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-4 h-12 font-bold text-white bg-gradient-to-br from-blue-800 to-blue-800 rounded-lg transition-all duration-300 focus:outline-none"
                                            type="submit"
                                        >
                                            <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-blue-300 animate-pulse group-hover:ring-0 transition duration-300" />
                                            <span>Get Started</span>
                                        </motion.button>
                                    </div>
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            )}

                            <motion.div
                                className="flex flex-row items-center justify-center space-x-2 overflow-hidden"
                            >
                                <span className="block text-sm text-gray-500 whitespace-nowrap flex-shrink-0">
                                    Industry Aligned Courses
                                </span>
                                <span className="inline-block mx-2 flex-shrink-0">
                                    <svg
                                        width={12}
                                        height={12}
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_3008_28964)">
                                            <mask
                                                id="mask0_3008_28964"
                                                style={{ maskType: "luminance" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={12}
                                                height={12}
                                            >
                                                <path d="M12 0H0V12H12V0Z" fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_3008_28964)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z"
                                                    fill="#D2DAD5"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span className="block text-sm text-gray-500 whitespace-nowrap flex-shrink-0">
                                    100% Job Assistance
                                </span>
                            </motion.div>

                        </motion.div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
