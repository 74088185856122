import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const LearnPractice = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <section className="py-12 md:py-24 xl:pb-64 bg-neutral-900">
      <div className="container mx-auto px-4">
        <div className="mb-12 md:mb-20 max-w-5xl">
          <h1 className="relative text-4xl sm:text-9xl xl:text-11xl text-white font-semibold font-heading">
            <span className="mr-3">Learn. Practice. Mentorship.</span>
            <span className="relative inline-block">
              <span className="relative z-10">Career Opportunities.</span>
              <span
                className="absolute -bottom-2 left-0 h-2.5 w-full rounded-full"
                style={{
                  background:
                    "linear-gradient(131deg, #F8EADA 26.82%, #E5F1FB 96.89%)",
                }}
              />
            </span>
          </h1>
        </div>
        <h3 className="mb-8 text-4xl text-white font-semibold tracking-tight font-heading">
          Offerings that boosts your career.
        </h3>
        <div className="flex flex-wrap -m-3">
          {/* Card 1 */}
          <div className="w-full md:w-1/2 xl:w-1/3 p-3" data-aos="fade-up">
            <div
              className="flex flex-col h-auto justify-center p-10 bg-white rounded-5xl"
              style={{
                background:
                  "linear-gradient(131deg, #EFECE9 26.82%, #E5F1FB 96.89%)",
              }}
            >
              <div className="flex flex-wrap items-center -m-3 mb-5">
                <div className="w-auto p-3">
                  <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                    <img
                      src="mirga-assets/images/features/sticker.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex-1 p-3">
                  <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                  Your career journey, Your curriculum
                  </h4>
                </div>
              </div>
              <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
              Don't get lost in the sea of online courses. We offer a curated selection of high-quality programs designed to propel your career forward. Set your goals, track your achievements, and unlock your potential with a personalized learning experience.
              </p>
            </div>
          </div>

          {/* Card 2 */}
          <div className="relative xl:top-20 w-full md:w-1/2 xl:w-1/3 p-3" data-aos="fade-up" data-aos-delay="100">
            <div
              className="mb-6 p-10 bg-white rounded-5xl"
              style={{
                background:
                  "linear-gradient(131deg, #EFECE9 26.82%, #E5F1FB 96.89%)",
              }}
            >
              <div className="flex items-center -m-3 mb-5 flex-nowrap">
                <div className="w-auto p-3">
                  <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                    <img
                      src="mirga-assets/images/features/sticker.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex-1 p-3">
                  <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                  Find Your Next Opportunity 
                  </h4>
                </div>
              </div>
              <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
              Our platform is more than just learning; it's about connecting you with the right opportunities with a diverse range of jobs and events tailored for your skills and interests. Explore the latest openings, register for upcoming events, and take charge of your career.
              </p>
            </div>
            <div
              className="p-10 bg-white rounded-5xl"
              style={{
                background:
                  "linear-gradient(131deg, #EFECE9 26.82%, #E5F1FB 96.89%)",
              }}
            >

              {/* half card */}
              <div className="flex items-center -m-3 mb-5 flex-nowrap">
                <div className="w-auto p-3">
                  <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                    <img
                      src="mirga-assets/images/features/sticker.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex-1 p-3">
                  <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                  Personalized Learning with AI 
                  </h4>
                </div>
              </div>
              <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
              Our AI-powered platform provides personalized guidance and resources, ensuring you stay motivated and on track. Whether you're a student or a professional. Learn at your own pace and build the skills you need to thrive. 
              </p>
            </div>
          </div>

          {/* Card 3 */}
          <div className="relative xl:top-40 w-full xl:w-1/3 p-3" data-aos="fade-up" data-aos-delay="200">
            <div
              className="flex flex-col h-auto justify-center p-10 bg-white rounded-5xl"
              style={{
                background:
                  "linear-gradient(131deg, #EFECE9 26.82%, #E5F1FB 96.89%)",
              }}
            >
              <div className="flex items-center -m-3 mb-5 flex-nowrap">
                <div className="w-auto p-3">
                  <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                    <img
                      src="mirga-assets/images/features/sticker.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex-1 p-3">
                  <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                  Be part of the fastest growing community
                  </h4>
                </div>
              </div>
              <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
              We believe that learning is a journey best traveled together. Become a part of a supportive and inspiring network of learners. Join us today and unlock your full potential as together we can achieve great heights!
              </p>
            </div>
          </div>
        </div>
      </div>
      
    </section>
    
    
  );
};

export default LearnPractice;
