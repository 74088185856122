import React, { useState, useEffect, useRef } from 'react';
import { useParams} from 'react-router-dom';
import { PlayCircle, BookOpen, Send, Bot, User, Loader2 } from 'lucide-react';
import { collection, getDocs, doc, updateDoc, arrayUnion, getDoc, setDoc } from 'firebase/firestore';
import { auth, db, model } from '../../firebase';
import ReactMarkdown from 'react-markdown';

const NotesSection = ({ lesson, noteLevel, setNoteLevel, noteTone, setNoteTone, noteLength, setNoteLength, generateNotesForLesson, generatingNotes }) => {
  const options = {
    levels: [
      { value: 'beginner', label: 'Beginner', icon: '🌱' },
      { value: 'intermediate', label: 'Intermediate', icon: '🌿' },
      { value: 'advanced', label: 'Advanced', icon: '🌳' }
    ],
    tones: [
      { value: 'formal', label: 'Formal', icon: '📚' },
      { value: 'informal', label: 'Informal', icon: '💭' },
      { value: 'humorous', label: 'Humorous', icon: '😊' }
    ],
    lengths: [
      { value: 'short', label: 'Short', icon: '⚡' },
      { value: 'medium', label: 'Medium', icon: '📝' },
      { value: 'long', label: 'Long', icon: '📄' }
    ]
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white rounded-xl shadow-md border border-gray-200 p-6 mb-6">
        <h3 className="text-lg font-semibold mb-4 text-gray-800">Customize Your Notes</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Note Level Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Comprehension Level</label>
            <div className="flex flex-col space-y-2">
              {options.levels.map(level => (
                <button
                  key={level.value}
                  onClick={() => setNoteLevel(level.value)}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-lg border-2 transition-all duration-200 ${
                    noteLevel === level.value
                      ? 'border-blue-500 bg-blue-50 text-blue-700'
                      : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'
                  }`}
                >
                  <span className="text-xl">{level.icon}</span>
                  <span>{level.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Note Tone Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Writing Style</label>
            <div className="flex flex-col space-y-2">
              {options.tones.map(tone => (
                <button
                  key={tone.value}
                  onClick={() => setNoteTone(tone.value)}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-lg border-2 transition-colors duration-200 ${
                    noteTone === tone.value
                      ? 'border-blue-500 bg-blue-50 text-blue-700'
                      : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
                  }`}
                >
                  <span className="text-xl">{tone.icon}</span>
                  <span>{tone.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Note Length Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Content Length</label>
            <div className="flex flex-col space-y-2">
              {options.lengths.map(length => (
                <button
                  key={length.value}
                  onClick={() => setNoteLength(length.value)}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-lg border-2 transition-colors duration-200 ${
                    noteLength === length.value
                      ? 'border-blue-500 bg-blue-50 text-blue-700'
                      : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
                  }`}
                >
                  <span className="text-xl">{length.icon}</span>
                  <span>{length.label}</span>
                </button>
              ))}
            </div>
          </div>
        </div>

        <button
          onClick={generateNotesForLesson}
          disabled={generatingNotes}
          className={`mt-6 w-full px-6 py-3 rounded-lg text-white font-medium transition-all duration-200 flex items-center justify-center space-x-2 ${
            generatingNotes 
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          {generatingNotes ? (
            <>
              <Loader2 className="w-5 h-5 animate-spin" />
              <span>Generating Notes...</span>
            </>
          ) : (
            <>
              <BookOpen className="w-5 h-5" />
              <span>Generate Notes</span>
            </>
          )}
        </button>
      </div>

      {lesson?.notes && (
        <div className="bg-white rounded-xl shadow-md border border-gray-100 overflow-hidden w-full">
        {/* Header */}
        <div className="border-b border-gray-200 bg-gray-50 px-6 py-4 flex items-center justify-between">
          <h3 className="text-lg font-semibold text-gray-800">Generated Notes</h3>
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <span>{options.levels.find(l => l.value === noteLevel)?.icon}</span>
            <span>{options.tones.find(t => t.value === noteTone)?.icon}</span>
            <span>{options.lengths.find(l => l.value === noteLength)?.icon}</span>
          </div>
        </div>
        {/* Content Section */}
        <div className="p-6 overflow-auto w-full">
          <div className="prose w-full max-w-none break-words text-gray-700 leading-relaxed">
            <ReactMarkdown>{lesson.notes}</ReactMarkdown>
          </div>
        </div>
      </div>
      
      )}
    </div>
  );
};

// New Component: ChatMessage
const ChatMessage = ({ message }) => {
  return (
    <div className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4 group`}>
      {message.sender === 'ai' && (
        <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center mr-2 flex-shrink-0">
          <Bot className="w-5 h-5 text-blue-600" />
        </div>
      )}
      <div className={`max-w-[80%] rounded-2xl px-4 py-3 ${
        message.sender === 'user' 
          ? 'bg-blue-100 text-black rounded-tr-none' 
          : 'bg-gray-100 text-gray-800 rounded-tl-none'
      }`}>
        <div className={`prose max-w-none ${message.sender === 'user' ? 'text-black' : 'text-gray-800'}`}>
          <ReactMarkdown>{message.text}</ReactMarkdown>
        </div>
        <div className={`text-xs mt-1 flex items-center justify-between ${
          message.sender === 'user' ? 'text-blue-100' : 'text-gray-500'
        }`}>
          
        </div>
      </div>
      {message.sender === 'user' && (
        <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center ml-2 flex-shrink-0">
          <User className="w-5 h-5 text-white" />
        </div>
      )}
    </div>
  );
};

// New Component: ChatInput
const ChatInput = ({ chatInput, setChatInput, handleChatSubmit, isLoading }) => {
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleChatSubmit(e);
    }
  };

  return (
    <form onSubmit={handleChatSubmit} className="mt-4 border-t pt-4">
      <div className="relative">
        <textarea
          ref={inputRef}
          rows="3"
          className="w-full px-4 py-3 border-2 rounded-2xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none pr-28 bg-gray-50 hover:bg-white transition-colors duration-200 resize-none"
          placeholder="Type your message here... (Press Enter to send)"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          style={{ minHeight: '80px', maxHeight: '160px' }}
        />
        <button
          type="submit"
          className={`absolute right-2 bottom-2 px-4 py-2 text-white rounded-xl hover:bg-blue-700 transition-colors duration-200 flex items-center space-x-2 ${
            chatInput.trim() ? 'bg-blue-600' : 'bg-gray-400 cursor-not-allowed'
          } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading || !chatInput.trim()}
        >
          {isLoading ? (
            <div className="flex items-center space-x-2">
              <Loader2 className="w-5 h-5 animate-spin" />
              <span className="text-sm">Sending...</span>
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <Send className="w-5 h-5" />
              <span className="text-sm">Send</span>
            </div>
          )}
        </button>
      </div>
      <div className="flex justify-between mt-2 text-xs text-gray-500">
        <span>Press Shift + Enter for new line</span>
      </div>
    </form>
  );
};

// New Component: ChatSection
const ChatSection = ({ chatMessages, chatInput, setChatInput, handleChatSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const predefinedQuestions = [
    'Key concepts explained in the video',
    'Clarification on specific topics',
    'Examples and applications',
    'Additional resources'
  ];

  const handlePredefinedQuestion = (question) => {
    setChatInput(question);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const wrappedHandleChatSubmit = async (e) => {
    if (!chatInput.trim()) return;
    setIsLoading(true);
    await handleChatSubmit(e);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col h-[700px] bg-white rounded-lg shadow-md border border-gray-100">
      <div className="p-4 border-b bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-t-lg">
        <div className="flex items-center space-x-2">
          <Bot className="w-6 h-6" />
          <div>
            <h3 className="text-lg font-semibold">AI Learning Assistant</h3>
            <p className="text-sm text-blue-100">Ask questions about this lesson</p>
          </div>
        </div>
      </div>
      
      <div 
        ref={chatContainerRef}
        className="flex-grow overflow-y-auto p-4 space-y-4 scroll-smooth"
        style={{ 
          scrollbarWidth: 'thin',
          scrollbarColor: '#CBD5E0rgb(6, 79, 152)'
        }}
      >
        {chatMessages.length === 0 ? (
          <div className="text-center text-gray-500 mt-8">
            <div className="mb-4">
              <Bot className="w-12 h-12 mx-auto text-blue-500" />
            </div>
            <h4 className="text-lg font-semibold mb-2">Welcome to AI Chat!</h4>
            <p className="text-sm">I'm here to help you understand this lesson better.</p>
            <p className="text-sm mt-2">Try asking questions about:</p>
            <div className="mt-4 space-y-2">
              {predefinedQuestions.map((question, index) => (
                <button
                  key={index}
                  onClick={() => handlePredefinedQuestion(question)}
                  className="w-full bg-gray-100 p-3 rounded-lg text-sm text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200 cursor-pointer flex items-center justify-between group"
                >
                  <span>{question}</span>
                  <Send className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
                </button>
              ))}
            </div>
          </div>
        ) : (
          chatMessages.map((message, index) => (
            <ChatMessage key={index} message={message} />
          ))
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-4 bg-gray-50 rounded-b-lg border-t border-gray-200">
        <ChatInput
          chatInput={chatInput}
          setChatInput={setChatInput}
          handleChatSubmit={wrappedHandleChatSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

const formatUrl = (text) => {
  if (!text) return '';
  return text.replace(/\s+/g, '-');
};

const videoID = (url) => {
  const videoID = url.split('v=')[1];
  const ampersandPosition = videoID.indexOf('&');
  if (ampersandPosition !== -1) {
    return videoID.substring(0, ampersandPosition);
  }
  return videoID;
};

const calculateProgress = (completedLessons, modules) => {
  const totalLessons = modules.reduce((acc, module) => acc + module.lessons.length, 0);
  const completedCount = completedLessons.length;
  return totalLessons === 0 ? 0 : Math.round((completedCount / totalLessons) * 100);
};

const CourseLesson = () => {
  const { course_title, module_title, lesson_slug } = useParams();
  const [course, setCourse] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const [isCompleted, setIsCompleted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openModules, setOpenModules] = useState([]);
  const [modules, setModules] = useState([]);
  const [completedLessons, setCompletedLessons] = useState([]);
  const [generatingNotes, setGeneratingNotes] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [noteLevel, setNoteLevel] = useState('beginner'); 
  const [noteTone, setNoteTone] = useState('formal'); 
  const [noteLength, setNoteLength] = useState('short'); 

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const formattedCourseTitle = course_title.replace(/-/g, ' ').toLowerCase();
  const formattedModuleTitle = module_title.replace(/-/g, ' ').toLowerCase();

  // Move fetchNotes definition above the useEffects and memoize it
  const fetchNotes = React.useCallback(async () => {
    if (!course || !lesson || !currentModule || !userId) {
        console.log('Missing required data for fetching notes:', { 
            hasCourse: !!course, 
            hasLesson: !!lesson,
            hasModule: !!currentModule,
            hasUserId: !!userId 
        });
        return;
    }

    try {
        // Create composite ID for the note
        const noteId = `${currentModule.id}_${lesson.id}`;
        console.log('Fetching notes with ID:', noteId);

        const notesRef = doc(db, 'enrollments', `${userId}_${course.id}`, 'generatedNotes', noteId);
        const notesDoc = await getDoc(notesRef);
        
        console.log('Notes document exists:', notesDoc.exists());
        
        if (notesDoc.exists()) {
            const notesData = notesDoc.data();
            console.log('Retrieved notes data:', notesData);
            
            // Update lesson state with notes and metadata
            setLesson(prevLesson => ({
                ...prevLesson,
                notes: notesData.notes,
                noteData: notesData
            }));
            
            // Update note preferences if they exist
            if (notesData.noteLevel) setNoteLevel(notesData.noteLevel);
            if (notesData.noteTone) setNoteTone(notesData.noteTone);
            if (notesData.noteLength) setNoteLength(notesData.noteLength);
        } else {
            console.log('No existing notes found for this lesson');
            // Clear notes from lesson state if none exist
            setLesson(prevLesson => ({
                ...prevLesson,
                notes: null,
                noteData: null
            }));
        }
    } catch (error) {
        console.error('Error fetching notes:', error);
    }
}, [course, lesson, currentModule, userId]);

// Separate useEffect for fetching notes
useEffect(() => {
    const isAuthenticated = !!auth.currentUser;
    if (!course || !lesson || !currentModule || !isAuthenticated) {
        console.log('Waiting for required data before fetching notes...', {
            hasCourse: !!course,
            hasLesson: !!lesson,
            hasModule: !!currentModule,
            isAuthenticated
        });
        return;
    }

    console.log('Fetching notes for lesson:', {
        courseId: course.id,
        moduleId: currentModule.id,
        lessonId: lesson.id,
        userId: auth.currentUser?.uid
    });

    fetchNotes();
}, [course, lesson, currentModule, fetchNotes]);

  const markAsComplete = async () => {
    if (!userId || !course || !currentModule || !lesson) return;

    const lessonKey = `${course.id}_${currentModule.id}_${lesson.id}`;

    if (completedLessons.includes(lessonKey)) {
      setIsCompleted(true);
      return;
    }

    try {
      const enrollmentPath = `enrollments/${userId}_${course.id}`;
      const enrollmentRef = doc(db, enrollmentPath);

      const updatedCompletedLessons = [...completedLessons, lessonKey];
      const newProgress = calculateProgress(updatedCompletedLessons, modules);

      const currentLessonIndex = currentModule.lessons.findIndex(l => l.id === lesson.id);
      const nextLesson = currentModule.lessons[currentLessonIndex + 1];
      const nextLessonKey = nextLesson ? `${course.id}_${currentModule.id}_${nextLesson.id}` : null;

      await updateDoc(enrollmentRef, {
        completedLessons: arrayUnion(lessonKey),
        progress: newProgress,
        currentLesson: nextLessonKey,
      });

      setCompletedLessons(updatedCompletedLessons);
      setProgress(newProgress);

      if (nextLessonKey) {
        console.log(`Navigating to next lesson: ${nextLesson.lesson_slug}`);
        window.location.href = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(currentModule.module_title)}/${formatUrl(nextLesson.lesson_slug)}`;
      } else {
        const currentModuleIndex = modules.findIndex(m => m.id === currentModule.id);
        const nextModule = modules[currentModuleIndex + 1];

        if (nextModule && nextModule.lessons.length > 0) {
          const firstLesson = nextModule.lessons[0];
          console.log("Moving to next module...");
          window.location.href = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(nextModule.module_title)}/${formatUrl(firstLesson.lesson_slug)}`;
        } else {
          console.log("Course fully completed.");
        }
      }
    } catch (error) {
      console.error('Error updating lesson completion:', error);
    }
  };

  const toggleModule = (moduleId) => {
    setOpenModules((prevOpenModules) =>
      prevOpenModules.includes(moduleId)
        ? prevOpenModules.filter((id) => id !== moduleId)
        : [...prevOpenModules, moduleId]
    );
  };

  const handleLessonClick = (lessonItem, moduleItem) => {
    if (!course || !moduleItem || !lessonItem || !currentModule) {
      console.error("Lesson or module is undefined:", { course, moduleItem, lessonItem, currentModule });
      return;
    }

    const lessonKey = `${course.id}_${moduleItem.id}_${lessonItem.id}`;
    const currentLessonIndex = currentModule.lessons.findIndex(l => l.id === lessonItem.id);
    const selectedLessonIndex = moduleItem.lessons.findIndex(l => l.id === lessonItem.id);
    const isModuleUnlocked = currentModule.id === moduleItem.id || currentModule.id > moduleItem.id;
    const isLessonCompleted = completedLessons.includes(lessonKey);
    const isLessonUnlocked = selectedLessonIndex <= currentLessonIndex;
    const isCurrentModuleComplete = currentModule.lessons.every(l => completedLessons.includes(`${course.id}_${currentModule.id}_${l.id}`));
    if (!isLessonCompleted && !isLessonUnlocked && isModuleUnlocked) {
      console.warn("This lesson is locked. Please complete the previous lesson first.");
      return;
    }
    if (moduleItem.id > currentModule.id && !isCurrentModuleComplete) {
      console.warn("Please complete all lessons in the current module before proceeding to the next module.");
      return;
    }
    window.location.href = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(moduleItem.module_title)}/${formatUrl(lessonItem.lesson_slug)}`;
  };

  const generateNotesForLesson = async () => {
    if (!course || !currentModule || !lesson || !userId) {
        console.log('Missing required data for generating notes:', {
            hasCourse: !!course,
            hasModule: !!currentModule,
            hasLesson: !!lesson,
            hasUserId: !!userId
        });
        return;
    }

    setGeneratingNotes(true);

    try {
        const prompt = `
            Generate **${noteLength}** high-quality notes for a **${noteLevel}** level lesson on **${lesson.lesson_title}** with a **${noteTone}** tone.

            - **Ensure clarity and conciseness.** Summarize key concepts without unnecessary details or repetition.  
            - **Structure the notes logically.** Use bullet points, numbered lists, or short paragraphs for readability.  
            - **Highlight the most important ideas.** Focus on core concepts and avoid minor details unless relevant.  
            - **Provide simple explanations for complex terms.** Ensure clarity without overwhelming the reader.  
            - **Use examples where necessary.** Keep them brief, relevant, and easy to understand.  
            - **Maintain a natural, engaging tone.** Avoid robotic phrasing and make the notes feel conversational when appropriate.  
            - **Avoid AI-related jargon.** The notes should feel human-like, without references to AI or summarization methods.  
            - **Ensure logical flow.** Present information in a way that makes sense, whether sequentially, thematically, or by importance.  
            - **Do not mention this prompt or instructions in the response.** The output should feel like naturally written notes, not AI-generated content.  
        `;

        const result = await model.generateContent({
            contents: [{ role: "user", parts: [{ text: prompt }] }],
            tools: [{ googleSearch: {} }],
        });

        const response = result.response;
        if (response?.candidates?.length > 0) {
            const notes = response.candidates[0].content.parts[0].text;
            
            // Create composite ID for the note
            const noteId = `${currentModule.id}_${lesson.id}`;
            console.log('Generating notes with ID:', noteId);

            // Store notes with correct path and IDs
            const notesRef = doc(db, 'enrollments', `${userId}_${course.id}`, 'generatedNotes', noteId);
            const noteData = {
                notes,
                noteLevel,
                noteTone,
                noteLength,
                generatedAt: new Date().toISOString(),
                lessonTitle: lesson.lesson_title,
                lessonId: lesson.id,
                moduleId: currentModule.id,
                courseId: course.id
            };

            await setDoc(notesRef, noteData);
            console.log('Notes saved successfully:', noteData);

            // Update local state
            setLesson(prevLesson => ({
                ...prevLesson,
                notes,
                noteData
            }));
        } else {
            console.error('Failed to generate notes:', response);
        }
    } catch (error) {
        console.error('Error generating notes:', error);
    } finally {
        setGeneratingNotes(false);
    }
};

// Modify the useEffect to properly handle auth state
useEffect(() => {
    const fetchCourseData = async () => {
        try {
            if (!auth.currentUser) {
                console.log('No authenticated user');
                return;
            }
            
            const userId = auth.currentUser.uid;
            console.log('Fetching course data for user:', userId);

            const coursesRef = collection(db, 'courses');
            const coursesSnapshot = await getDocs(coursesRef);
            const courseDoc = coursesSnapshot.docs.find(
                (doc) => doc.data().course_title.toLowerCase() === formattedCourseTitle
            );

            if (!courseDoc) {
                console.warn('No course found with the given title:', formattedCourseTitle);
                return;
            }

            const courseData = { id: courseDoc.id, ...courseDoc.data() };
            console.log('Course data loaded:', courseData.id);
            setCourse(courseData);

            const modulesRef = collection(db, 'courses', courseDoc.id, 'modules');
            const modulesSnapshot = await getDocs(modulesRef);
            const modulesData = [];

            for (const moduleDoc of modulesSnapshot.docs) {
                const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };

                const lessonsRef = collection(db, 'courses', courseDoc.id, 'modules', moduleDoc.id, 'lessons');
                const lessonsSnapshot = await getDocs(lessonsRef);
                const lessons = lessonsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                moduleData.lessons = lessons;
                modulesData.push(moduleData);

                if (moduleData.module_title.toLowerCase() === formattedModuleTitle) {
                    setCurrentModule(moduleData);
                    const lessonDoc = lessons.find(
                        (lesson) => lesson.lesson_slug.toLowerCase() === lesson_slug
                    );

                    if (!lessonDoc) {
                        console.warn('No lesson found with the given title:', lesson_slug);
                        return;
                    }

                    console.log('Setting lesson:', lessonDoc.id);
                    setLesson(lessonDoc);
                    setOpenModules((prevOpenModules) => [...prevOpenModules, moduleData.id]);
                }
            }

            setModules(modulesData);

            const enrollmentDocRef = doc(db, 'enrollments', `${userId}_${courseData.id}`);
            const enrollmentDoc = await getDoc(enrollmentDocRef);
            if (enrollmentDoc.exists()) {
                const enrollmentData = enrollmentDoc.data();
                setCompletedLessons(enrollmentData.completedLessons || []);
                setProgress(calculateProgress(enrollmentData.completedLessons || [], modulesData));
                if (enrollmentData.completedLessons.includes(`${courseData.id}_${currentModule?.id}_${lesson?.id}`)) {
                    setIsCompleted(true);
                }
            }
        } catch (error) {
            console.error('Error in fetchCourseData:', error);
        }
    };

    fetchCourseData();
  }, [formattedCourseTitle, formattedModuleTitle, lesson_slug, currentModule?.id, lesson?.id]);

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    if (!chatInput.trim()) return;

    const userMessage = { text: chatInput, sender: 'user' };
    setChatMessages(prevMessages => [...prevMessages, userMessage]);
    setChatInput('');

    try {
      const initialContext = `You are an advanced AI assistant designed to help students with the YouTube video embedded in the lesson is ${lesson.youtubeUrl} lesson titled **"${lesson.lesson_title}"**.
      The video owner is ${lesson.instructor}  
      - Provide **clear, accurate, and well-structured explanations** based on the lesson topic.  
      - Summarize key points effectively, using **concise language and structured formatting**.  
      - Offer **examples, step-by-step breakdowns, or real-world applications** where relevant.  
      - **Do not explicitly mention sources, external links, or the lesson URL unless the user specifically asks.**  
      - Ensure **a conversational, engaging, and insightful** tone that adapts to the user's needs.  
      - **Never credit 1xSkills for video creation.** If asked about your creator, mention **1xSkills**, but **only in response to direct questions**.  
      - Use the lesson's information to generate responses, but **do not display or reference the lesson URL** unless explicitly requested.  

      `;

      const prompt = `${initialContext}\n\nUser: ${chatInput}`;

      const result = await model.generateContent({
        contents: [{ role: "user", parts: [{ text: prompt }] }],
      });
      const response = result.response;
      if (response && response.candidates && response.candidates.length > 0) {
        const aiMessage = { text: response.candidates[0].content.parts[0].text, sender: 'ai' };
        setChatMessages(prevMessages => [...prevMessages, aiMessage]);
      } else {
        console.error('Failed to generate chat response:', response);
      }
    } catch (error) {
      console.error('Error generating chat response:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'description':
        return lesson?.description || 'Description is not available right now.';
      case 'notes':
        return (
          <NotesSection
            lesson={lesson}
            noteLevel={noteLevel}
            setNoteLevel={setNoteLevel}
            noteTone={noteTone}
            setNoteTone={setNoteTone}
            noteLength={noteLength}
            setNoteLength={setNoteLength}
            generateNotesForLesson={generateNotesForLesson}
            generatingNotes={generatingNotes}
          />
        );
      case 'Chat':
        return (
          <div className="h-full">
            <ChatSection
              chatMessages={chatMessages}
              chatInput={chatInput}
              setChatInput={setChatInput}
              handleChatSubmit={handleChatSubmit}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (!course || !lesson || !currentModule) {
    return <div className="text-center text-gray-600 font-semibold py-8">Loading lesson data...</div>;
  }

  const youtubevideoid = `${videoID(lesson.youtubeUrl)}`;

  return (
    <div className="container mx-auto px-2 py-2 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold text-gray-900 flex-shrink-0 mb-6">{course.course_title}</h2>

      <div className="grid lg:grid-cols-[75%,auto] gap-8">
        {/* lesson area */}
        <div className="bg-white shadow-sm rounded-lg p-6 border border-gray-200">
          <h3 className="text-xl font-bold text-gray-900 flex-shrink-0 mb-6">{lesson.lesson_title}</h3>

          <div className="relative aspect-video border border-gray-300 bg-gray-50 rounded-lg overflow-hidden">
            <iframe
              className="absolute inset-0 w-full h-full"
              src={`https://www.youtube.com/embed/${youtubevideoid}?rel=0&modestbranding=1&controls=1`}
              title={lesson.lesson_title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div className="mt-8">
            {/* Tabbed Interface */}
            <div className="flex border-b border-gray-200 mb-4">
              {['description', 'notes', 'Chat'].map(tab => (
                <button
                  key={tab}
                  className={`px-4 py-2 text-lg font-medium transition-colors duration-200 border-b-2 ${activeTab === tab ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-600 hover:text-blue-500'}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            <div className="text-gray-700 text-base md:text-lg">
              {renderContent()}
            </div>

            <button
              onClick={markAsComplete}
              className={`mt-4 px-4 py-2 text-white bg-blue-800 rounded ${isCompleted ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isCompleted}
            >
              {isCompleted ? 'Completed' : 'Mark as Complete'}
            </button>
          </div>
        </div>
        {/* Right Side Bar */}
        <div className="bg-white shadow-sm rounded-lg p-6 border border-gray-200">
          <p className="text-gray-600 mb-2">Course Progress</p>
          <p className="text-blue-800 mb-4">{progress}% Complete</p>
          <div className="w-full bg-gray-200 rounded-full h-1 mb-4">
            <div className="bg-blue-800 h-1 rounded-full transition-all duration-500" style={{ width: `${progress}%` }}></div>
          </div>
          {/*Module accordion section*/}
          <div className="space-y-4">
            {modules.map((moduleItem) => (
              <div key={moduleItem.id} className="border rounded-lg overflow-hidden">
                <button
                  className="w-full text-left px-4 py-2 bg-gray-100 text-gray-800 font-semibold flex justify-between items-center"
                  onClick={() => toggleModule(moduleItem.id)}
                >
                  <span className="flex-grow">{moduleItem.module_title}</span>
                </button>
                {openModules.includes(moduleItem.id) && (
                  <div className="px-4 py-2 bg-white">
                    {Array.isArray(moduleItem.lessons) && moduleItem.lessons.length > 0 ? (
                      <ul className="mt-2 text-gray-600 space-y-2 pl-4">
                        {moduleItem.lessons.map((lessonItem) => (
                          <li key={lessonItem.id} className="flex justify-between items-center py-2 border-b">
                            <div className="flex items-center space-x-2">
                              <button
                                onClick={() => handleLessonClick(lessonItem, moduleItem)} // Ensure moduleItem is passed here
                                className={`flex items-center space-x-3 text-base ${completedLessons.includes(`${course.id}_${moduleItem.id}_${lessonItem.id}`) ? 'text-green-600' : 'text-gray-700 hover:text-blue-600 transition-colors'
                                  }`}
                              >
                                <PlayCircle className={`w-5 h-5 flex-shrink-0 ${completedLessons.includes(`${course.id}_${moduleItem.id}_${lessonItem.id}`) ? 'text-green-500' : 'text-gray-500'
                                  }`} />
                                <span className="pl-2 flex-grow text-left">{lessonItem.lesson_title}</span>
                              </button>
                            </div>
                            <span className="text-sm text-gray-500 flex-shrink-0">{lessonItem.duration}</span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="mt-2 text-gray-500 pl-4">No lessons available.</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLesson;
