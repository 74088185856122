import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { useUser } from '../../utils/UserContext'; 

const UserProfile = () => {
    const { user } = useUser(); 
    const [profileData, setProfileData] = useState(null);
    const [personalData, setPersonalData] = useState({
        dob: '',
        gender: '',
        state: '',
        currentRole: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newProfilePhoto, setNewProfilePhoto] = useState(null);

    useEffect(() => {
        const fetchProfileData = async () => {
            const userDocRef = doc(db, 'users', user.uid);
            const userProfileRef = doc(userDocRef, 'UserProfile', user.uid);
            const userPersonalDataRef = doc(userDocRef, 'PersonalInfo', user.uid);
            const userProfileSnapshot = await getDoc(userProfileRef);
            const userPersonalDataSnapshot = await getDoc(userPersonalDataRef);
            if (userProfileSnapshot.exists()) {
                setProfileData(userProfileSnapshot.data());
            }
            if (userPersonalDataSnapshot.exists()) {
                setPersonalData({
                    dob: userPersonalDataSnapshot.data().dob || '',
                    gender: userPersonalDataSnapshot.data().gender || '',
                    state: userPersonalDataSnapshot.data().state || '',
                    currentRole: userPersonalDataSnapshot.data().currentRole || ''
                });
            }
        };

        fetchProfileData();
    }, [user]);

    useEffect(() => {
        if (isPopupOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.position = 'static';
            document.body.style.width = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
            document.body.style.position = 'static';
            document.body.style.width = 'auto';
        };
    }, [isPopupOpen]);

    const handleEditToggle = () => {
        setIsEditing((prev) => !prev);
        setIsChanged(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPersonalData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setIsChanged(true);
    };

    const handleSave = async () => {
        const userDocRef = doc(db, 'users', user.uid);
        const userPersonalDataRef = doc(userDocRef, 'PersonalInfo', user.uid);

        try {
            await updateDoc(userPersonalDataRef, {
                dob: personalData.dob.split('/').reverse().join('-'), // Convert dd/mm/yyyy to yyyy-mm-dd
                gender: personalData.gender,
                state: personalData.state,
                currentRole: personalData.currentRole,
            });
            setIsEditing(false);
            setIsChanged(false);
        } catch (error) {
            console.error('Error updating personal data:', error);
        }
    };

    const sanitizePhotoURL = (url) => {
        if (url?.startsWith('"') && url?.endsWith('"')) {
            return url.slice(1, -1);
        }
        return url;
    };

    const handleProfilePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewProfilePhoto(URL.createObjectURL(file));
            setIsPopupOpen(false);
        }
    };

    const formatDate = (date) => {
        if (!date) return 'N/A';
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    };

    const formatInputDate = (date) => {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${year}-${month}-${day}`;
    };

    if (!profileData) {
        return <p>Loading...</p>;
    }

    return (
        <section className='bg-white py-8'>
            <div className='max-w-4xl mx-auto px-6'>
                <div className='flex flex-col items-center'>
                    <img
                        src={newProfilePhoto || sanitizePhotoURL(user?.profilePhoto) || 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'}
                        alt="User Profile"
                        className='w-32 h-32 rounded-full object-cover mb-4 shadow-lg'
                    />
                    {isEditing && (
                        <button
                            type="button"
                            className="text-blue-800 hover:text-blue-600 transition duration-300 mb-4"
                            onClick={() => setIsPopupOpen(true)}
                        >
                            Change Profile Picture
                        </button>
                    )}
                    <h3 className='text-2xl font-semibold mb-2'>
                        {profileData.firstName} {profileData.lastName}
                    </h3>
                    {!isEditing && (
                        <button
                            type="button"
                            className="bg-blue-800 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300"
                            onClick={handleEditToggle}
                        >
                            Edit Profile
                        </button>
                    )}
                </div>
                <div className='space-y-6 mt-6'>
                    <div>
                        <label className='block text-gray-700 font-medium'>Date of Birth</label>
                        {isEditing ? (
                            <input
                                type="date"
                                name="dob"
                                value={formatInputDate(personalData.dob) || ''}
                                onChange={handleChange}
                                className="w-full p-2 rounded border border-gray-300 text-gray-600"
                                placeholder="dd/mm/yyyy"
                            />
                        ) : (
                            <p className='p-2 text-base border border-gray-300 rounded text-gray-600'>
                                {formatDate(personalData.dob)}
                            </p>
                        )}
                    </div>

                    <div>
                        <label className='block text-gray-700 font-medium'>Gender</label>
                        {isEditing ? (
                            <select
                                name="gender"
                                value={personalData.gender || ''}
                                onChange={handleChange}
                                className="w-full p-2 rounded border border-gray-300 text-gray-600"
                            >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        ) : (
                            <p className='p-2 text-base border border-gray-300 rounded text-gray-600'>
                                {personalData.gender || 'N/A'}
                            </p>
                        )}
                    </div>

                    <div>
                        <label className='block text-gray-700 font-medium'>State</label>
                        {isEditing ? (
                            <input
                                type="text"
                                name="state"
                                value={personalData.state || ''}
                                onChange={handleChange}
                                className="w-full p-2 rounded border border-gray-300 text-gray-600"
                            />
                        ) : (
                            <p className='p-2 text-base border border-gray-300 rounded text-gray-600'>
                                {personalData.state || 'N/A'}
                            </p>
                        )}
                    </div>

                    <div>
                        <label className='block text-gray-700 font-medium'>Current Role</label>
                        {isEditing ? (
                            <select
                                name="currentRole"
                                value={personalData.currentRole || ''}
                                onChange={handleChange}
                                className="w-full p-2 rounded border border-gray-300 text-gray-600"
                            >
                                <option value="Student">Student</option>
                                <option value="Learner">Learner</option>
                                <option value="Working Professional">Working Professional</option>
                            </select>
                        ) : (
                            <p className='p-2 text-base border border-gray-300 rounded text-gray-600'>
                                {personalData.currentRole || 'N/A'}
                            </p>
                        )}
                    </div>
                </div>
                {isEditing && (
                    <div className='flex justify-end mt-6 space-x-4'>
                        <button
                            type="button"
                            className="text-gray-700 hover:text-gray-500 transition duration-300 mr-6"
                            onClick={handleEditToggle}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                             className={`bg-gray-900 text-white px-4 py-2 rounded-lg transition duration-300 ${isChanged ? 'hover:bg-gray-800' : 'opacity-50 cursor-not-allowed'}`}
                            onClick={handleSave}
                            disabled={!isChanged}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
            {isPopupOpen && (
                <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 overflow-hidden'>
                    <div className='bg-white p-8 rounded-lg shadow-2xl relative border border-gray-300 max-w-md w-full mx-4 sm:mx-0'>
                        <button
                            type="button"
                            className="absolute top-0 right-0 m-4 text-gray-600 hover:text-red-600 transition duration-300 mt-2 mr-2"
                            onClick={() => setIsPopupOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h2 className='text-2xl font-semibold mb-6 text-center'>Change Profile Picture</h2>
                        <input type="file" accept="image/*" onChange={handleProfilePhotoChange} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" />
                        <div className='flex justify-end mt-6'>
                            <button
                                type="button"
                                className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300"
                                onClick={() => setIsPopupOpen(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default UserProfile;
