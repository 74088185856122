import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AllCourses from '../components/courses/AllCourses';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
        <AllCourses/>
    
     

    </React.Fragment>
  );
}

