import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext'; 

const ProtectedRoute = ({ element, shouldAllowAccess = () => true, redirectTo = '/' }) => {
  const { user } = useUser();

  if (user === undefined) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }
  if (!shouldAllowAccess(user)) {
    return <Navigate to={redirectTo} />;
  }

  return element;
};

export default ProtectedRoute;
