import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function _404() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative py-12 md:py-24 bg-neutral-50'>
          <div className='container mx-auto px-4'>
            <div className='relative z-10 px-12 py-16 bg-white rounded-4xl'>
              <div className='flex flex-wrap -m-4 divide-y md:divide-y-0 md:divide-x divide-neutral-50'>
                <div className='w-full md:w-2/3 p-4'>
                  <h1 className='relative mb-12 text-5xl sm:text-6xl md:text-9xl xl:text-11xl text-neutral-800 font-semibold font-heading'>
                    <span className='mr-3'>Shh* Something</span>
                    <span className='relative inline-block'>
                      <span className='relative z-10'>has wrong.</span>
                      <span className='absolute -bottom-2 left-0 h-2.5 w-full bg-green-200 rounded-full' />
                    </span>
                  </h1>
                  <p className='mb-12 md:mb-80 text-2xl text-neutral-600 font-semibold tracking-tight'>
                    That page isn’t doesn’t exist or we can’t find it.
                  </p>
                </div>
                <div className='w-full md:w-1/3 p-4'>
                  <div className='py-12 md:py-0'>
                    <div className='max-w-max mx-auto'>
                      <img
                        className='mx-auto object-cover h-64'
                        src='mirga-assets/images/http-codes/face.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
              <a
                className='inline-flex justify-center items-center text-center h-20 p-5 font-semibold tracking-tight text-2xl text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                href='/'
              >
                Homepage
              </a>
            </div>
          </div>
          <img
            className='absolute bottom-0 right-0'
            src='mirga-assets/images/http-codes/404.png'
            alt=''
          />
        </section>
      </>
    </React.Fragment>
  );
}
