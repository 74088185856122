import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useUser } from '../../utils/UserContext';

// Logo Component
const Logo = ({ src, alt }) => (
  <Link className="inline-block" to="/" aria-label="Home">
    <img className="h-14" src={src} alt={alt} />
  </Link>
);

// Menu Items Component
const MenuItems = ({ items, onClick }) => (
  <div className="hidden lg:flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center">
    {items.map((item, index) => (
      <Link
        key={index}
        className="inline-block hover:text-blue-700 mr-10"
        to={item.href}
        onClick={onClick}
      >
        {item.label}
      </Link>
    ))}
  </div>
);

// User Menu Component
const UserMenu = ({ user, logout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Close dropdown on route change
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location.pathname]);

  const sanitizePhotoURL = (url) => {
    if (url?.startsWith('"') && url?.endsWith('"')) {
      return url.slice(1, -1); 
    }
    return url;
  }

  return (
    <div className="relative">
      <button
        className="flex items-center space-x-4"
        onClick={toggleDropdown}
        aria-label="User Menu"
      >
       <img 
          src={sanitizePhotoURL(user?.profilePhoto) || 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'} 
          alt="User Profile" 
          className="w-8 h-8 rounded-full"
        />

        <span className="p-1 text-gray-700">{user?.firstName || 'Hello'}</span>
      </button>
      {/* Dropdown */}
      {isDropdownOpen && (
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-32 bg-white border border-gray-300 rounded-lg shadow-lg">
          <Link
            to="/manage-profile"
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 no-wrap"
          >
            Profile
          </Link>
          <Link
            to="/my-courses"
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 no-wrap shrink-0"
          >
            My Courses
          </Link>
          <button
            onClick={logout}
            className="block w-full text-left px-4 py-2 text-red-700 hover:bg-red-100 no-wrap"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

// Buttons Component
const Buttons = ({ user, logout }) => (
  <div className="hidden lg:flex ml-auto items-center">
    {user ? (
      <UserMenu user={user} logout={logout} />
    ) : (
      <>
        <Link
          className="inline-flex items-center justify-center mr-4 h-10 px-4 text-center leading-loose text-sm text-gray-700 hover:text-blue-700 font-semibold border border-gray-200 hover:border-blue-600 shadow-md hover:shadow-none rounded-lg transition duration-200"
          to="/login"
        >
          Login
        </Link>
        <Link
          className="inline-flex items-center justify-center h-10 px-4 text-center leading-loose text-sm text-white font-semibold border border-blue-700 bg-gradient-to-b from-blue-800 to-blue-600 rounded-lg transition duration-200 hover:bg-blue-600 shadow-lg"
          to="/register"
        >
          Get Started
        </Link>
      </>
    )}
  </div>
);

// Burger Button Component
const BurgerButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="lg:hidden ml-auto flex items-center justify-center h-10 w-10"
    aria-label="Toggle Mobile Menu"
  >
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5H21"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12H21"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 19H21"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);

// Mobile Menu Component
const MobileMenu = ({ isOpen, onClose, logoSrc, logoAlt, items, user, logout }) => {
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);

  const toggleLogoutVisibility = () => {
    setIsLogoutVisible(!isLogoutVisible);
  };

  const sanitizePhotoURL = (url) => {
    if (url?.startsWith('"') && url?.endsWith('"')) {
      return url.slice(1, -1); 
    }
    return url;
  };

  return (
    <div
      className={`fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50 h-full bg-white overflow-y-auto transition-all shadow-lg ${
        isOpen ? 'block' : 'hidden'
      }`}
    >
      <div
        className="fixed inset-0 bg-white-800 opacity-70"
        onClick={onClose}
        aria-label="Close Menu"
      />
      <nav className="relative flex flex-col py-6 px-6 w-full h-auto">
        <div className="flex mb-auto items-center">
          <Link className="inline-block mr-auto" to="/" onClick={onClose}>
            <img className="h-12" src={logoSrc} alt={logoAlt} />
          </Link>
          <button
            className="navbar-close"
            onClick={onClose}
            aria-label="Close Menu"
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18L18 6M6 6L18 18"
                stroke="#111827"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <ul className="py-12">
          {items.map((item, index) => (
            <li key={index} className="mb-4">
              <Link
                className="inline-block text-black"
                to={item.href}
                onClick={onClose}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
        <div>
          {user ? (
            <div className="flex flex-col items-start space-y-4">
              <button
                onClick={toggleLogoutVisibility}
                className="flex items-center space-x-4"
              >
                <img 
                  src={sanitizePhotoURL(user?.profilePhoto) || 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'} 
                  alt="User Profile" 
                  className="w-8 h-8 rounded-full"
                />
                <span className="text-gray-700 pl-2">{user?.firstName || 'Hello'}</span>
              </button>
              {isLogoutVisible && (
                <Link
                  to="/manage-profile"
                  onClick={onClose}
                  className=" text-gray-700 px-4"
                >
                  Profile
                </Link>
              )}
              {isLogoutVisible && (
                <Link
                  to="/my-courses"
                  onClick={onClose}
                  className=" text-gray-700 px-4"
                >
                  My Courses
                </Link>
              )}
              {isLogoutVisible && (
                <button
                  onClick={() => {
                      logout(); 
                      onClose();
                  }}
                  className="text-gray-700 px-4"
                >
                  Logout
                </button>
              )}
            </div>
          ) : (
            <>
              <Link
                className="flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200"
                to="/login"
                onClick={onClose}
              >
                Login
              </Link>
              <Link
                className="flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-blue-800 to-blue-600 rounded-lg transition duration-200 hover:bg-blue-600 shadow-lg"
                to="/register"
                onClick={onClose}
              >
                Get Started
              </Link>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

// Navbar Component
const Navbar = ({ logoSrc, logoAlt, menuItems }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, logout } = useUser();

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <section className="py-12-smaller lg:py-20">
      <nav className="shadow-md fixed top-0 left-0 right-0 z-50 bg-white">
        <div className="container mx-auto px-4">
          <div className="relative flex h-24 items-center">
            <Logo src={logoSrc} alt={logoAlt} />
            <BurgerButton onClick={toggleMobileMenu} />
            <MenuItems items={menuItems} onClick={() => setIsMobileMenuOpen(false)} />
            <Buttons user={user} logout={logout} />
          </div>
        </div>
      </nav>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        logoSrc={logoSrc}
        logoAlt={logoAlt}
        items={menuItems}
        user={user}
        logout={logout}
      />
    </section>
  );
};

// Default Menu Items
const menuItems = [
  { label: 'Courses', href: '/all-courses' },
  { label: 'Jobs', href: '/not-available' },
  { label: 'Roadmaps', href: '/not-available' },
  { label: 'Career Path', href: '/not-available' },
];

// Main Component
const Nav = () => (
  <Navbar
    logoSrc="/1xSkills-Logo.webp"
    logoAlt="1xSkills"
    menuItems={menuItems}
  />
);

export default Nav;
