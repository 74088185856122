import React from 'react';

export default function Footer() {
  return (
    <React.Fragment>
      <section className="py-12 md:pt-24">
        <div className="container mx-auto px-4">
        {/*  
          <div className="max-w-5xl mx-auto">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              
              <div>
                <h5 className="text-xl font-semibold ml-8 mb-6">Cohorts</h5>
                <ul>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Features
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Solutions
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Tutorials
                    </a>
                  </li>
                </ul>
              </div>

              
              <div>
                <h5 className="text-xl font-semibold ml-8 mb-6">Company</h5>
                <ul>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      About
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Our Mission
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>

              
              <div>
                <h5 className="text-xl font-semibold ml-8 mb-6">Resources</h5>
                <ul>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Press
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Blog
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Help Center
                    </a>
                  </li>
                  <li>
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Support
                    </a>
                  </li>
                </ul>
              </div>

              {
              <div>
                <h5 className="text-xl font-semibold ml-8 mb-6">Legal</h5>
                <ul>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Terms of Use
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="mb-4">
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Legal Notice
                    </a>
                  </li>
                  <li>
                    <a className="text-lg text-gray-600 hover:text-gray-900" href="/">
                      Cookie Settings
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          */}
          

          {/* Footer Bottom Section */}
          <div className="mt-12 pt-8 border-t border-gray-200">
            <div className="grid grid-cols-1 md:grid-cols-3 items-center text-center md:text-left">
              {/* Logo Section */}
              <div className="flex items-center justify-center md:justify-start">
                <img
                  src="/1xSkills-Logo.webp"
                  alt='1xSkills'
                  className="h-14"
                />
                <img 
                 src='https://developers.google.com/static/youtube/images/developed-with-youtube-sentence-case-dark.png'
                  alt='Developed with YouTube'
                  className='h-20 mt-5'
                />
              </div>

              {/* Text Section */}
              <div className="flex flex-col items-center justify-center text-center">
                <p className="text-gray-500">
                  © 2025 1xSkills from 1 Percent Verse Private Limited.
                </p>
              </div>

              {/* Link or Social Icon Section */}
              <div className="flex justify-center md:justify-end mt-6 space-x-4">
                <a href="https://www.instagram.com/1xskills" target="_blank" rel="noopener noreferrer">
                  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_231_5728)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0834 5.83317C19.0834 3.11983 16.8801 0.916504 14.1667 0.916504H5.83342C3.12008 0.916504 0.916748 3.11983 0.916748 5.83317V14.1665C0.916748 16.8798 3.12008 19.0832 5.83342 19.0832H14.1667C16.8801 19.0832 19.0834 16.8798 19.0834 14.1665V5.83317ZM17.5834 5.83317V14.1665C17.5834 16.0523 16.0526 17.5832 14.1667 17.5832H5.83342C3.94758 17.5832 2.41675 16.0523 2.41675 14.1665V5.83317C2.41675 3.94733 3.94758 2.41649 5.83342 2.41649H14.1667C16.0526 2.41649 17.5834 3.94733 17.5834 5.83317ZM14.0751 9.36484C13.8126 7.59067 12.4092 6.18733 10.6351 5.92483C10.4367 5.89483 10.2367 5.88067 10.0359 5.88067C7.79591 5.88067 5.95258 7.724 5.95258 9.964C5.95258 12.204 7.79591 14.0473 10.0359 14.0473C12.2759 14.0473 14.1192 12.204 14.1192 9.964C14.1192 9.76317 14.1051 9.56317 14.0751 9.36484ZM12.5917 9.58483C12.6101 9.71066 12.6192 9.83733 12.6192 9.964C12.6192 11.3807 11.4534 12.5473 10.0359 12.5473C8.61925 12.5473 7.45258 11.3807 7.45258 9.964C7.45258 8.5465 8.61925 7.38066 10.0359 7.38066C10.1626 7.38066 10.2892 7.38983 10.4151 7.40816C11.5376 7.57483 12.4251 8.46233 12.5917 9.58483ZM14.5834 4.6665C14.9976 4.6665 15.3334 5.00233 15.3334 5.4165C15.3334 5.83067 14.9976 6.16649 14.5834 6.16649C14.1692 6.16649 13.8334 5.83067 13.8334 5.4165C13.8334 5.00233 14.1692 4.6665 14.5834 4.6665Z" fill="#808080" />
                    </g>
                    <defs>
                      <clipPath id="clip0_231_5728">
                        <rect width="38" height="38 " fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="https://linkedin.com/company/1xskills" target="_blank" rel="noopener noreferrer">
                  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.667 2.5H3.333C2.596 2.5 2 3.096 2 3.833v12.334c0 .737.596 1.333 1.333 1.333h13.334c.737 0 1.333-.596 1.333-1.333V3.833C18 3.096 17.404 2.5 16.667 2.5zM6.222 15H4.444V7.778h1.778V15zM5.333 6.667a1.111 1.111 0 1 1 0-2.222 1.111 1.111 0 0 1 0 2.222zM15.556 15h-1.778v-3.556c0-1.046-.313-1.778-1.222-1.778-.673 0-1.112.45-1.3.884-.063.15-.1.355-.1.567V15h-1.778V9.889h1.778v.8c.262-.404.748-.889 1.733-.889 1.262 0 2.222.846 2.222 2.67V15z" fill="#808080" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="38" height="38" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="https://x.com/1xskills" target="_blank" rel="noopener noreferrer">
                  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8216 8.98736L17.9648 2H16.5092L11.1727 8.06576L6.91356 2H2L8.44212 11.1733L2 18.5H3.45554L9.08757 12.0929L13.5864 18.5H18.5L11.8216 8.98736ZM9.82739 11.2538L9.17367 10.3398L3.98044 3.07371H6.21644L10.4088 8.93987L11.0597 9.8539L16.5085 17.4786H14.2725L9.82739 11.2538Z" fill="#808080" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}