import React, { useEffect, useState } from 'react';
import { useUser } from '../../utils/UserContext';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase'; 
import { collection, getDocs, query, where } from 'firebase/firestore';

const formatURL = (courseTitle) => courseTitle.replace(/\s/g, '-');

const generateRandomGradient = (course_title) => {
  let hash = 0;
  for (let i = 0; i < course_title.length; i++) {
    hash = course_title.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h1 = hash % 360;
  const h2 = (hash + 100) % 360;
  return {
    color1: `hsl(${h1}, 70%, 70%)`,
    color2: `hsl(${h2}, 70%, 50%)`,
  };
};

const MyCourses = () => {
    const { user } = useUser();
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            if (user) {
                try {
                    const userEnrollmentsRef = collection(db, `users/${user.uid}/Enrollments`);
                    const snapshot = await getDocs(userEnrollmentsRef);

                    const enrolledCoursesID = snapshot.docs.flatMap(doc => doc.data().enrolledCourses).filter(Boolean);

                    if (enrolledCoursesID.length === 0) {
                        setCourses([]);
                        return;
                    }

                    const coursesDetails = await Promise.all(enrolledCoursesID.map(async (enrolledCourseID) => {
                        const coursesQuery = query(collection(db, 'courses'), where('courseID', '==', enrolledCourseID));
                        const coursesSnapshot = await getDocs(coursesQuery);
                        const courseData = coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        return courseData;
                    }));

                    setCourses(coursesDetails.flat());
                } catch (error) {
                    console.error('Error fetching courses:', error);
                }
            }
        };
        fetchCourses();
    }, [user]);

    const handleCourseClick = (course) => {
        navigate(`/course/${formatURL(course.course_title)}`);
    };

    return (
        <div className="container mx-auto px-4 py-8 lg:-mt-16">
            <h1 className="text-4xl font-bold mb-6 text-center">My Courses</h1>
            {courses.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {courses.map((course) => {
                        const { color1, color2 } = generateRandomGradient(course.course_title);
                        return (
                            <div 
                                key={course.id} 
                                className="mt-6 rounded-lg shadow-lg overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105"
                                style={{ background: `linear-gradient(135deg, ${color1}, ${color2})` }}
                                onClick={() => handleCourseClick(course)}
                            >
                                <div className="p-6 text-white">
                                    <h2 className="text-xl font-semibold mb-2">{course.course_title}</h2>
                                    <p className="text-sm opacity-80">{course.instructor || 'Unable to get instructor right now'}</p>
                                    <div className="mt-4 text-sm font-medium bg-white text-gray-800 px-4 py-2 rounded-md inline-block">View Course</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p className="text-center text-gray-500">No courses enrolled yet.</p>
            )}
        </div>
    );
};

export default MyCourses;
